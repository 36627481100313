import { CloseIcon, HamburgerIcon } from '@chakra-ui/icons';
import { Box, Flex, Text } from '@chakra-ui/layout';
import React, { ReactElement, Ref, useState } from 'react';
import { Collapse } from '@chakra-ui/transition';
import { graphql, Link as GatsbyLink, useStaticQuery } from 'gatsby';

import { useI18next } from 'gatsby-plugin-react-i18next';
import { Link } from '@chakra-ui/layout';
import { GatsbyImage, getImage, IGatsbyImageData } from 'gatsby-plugin-image';

export const headerHeightInPx = 80;

export interface IMenuItem {
  children: [ReactElement];
  navRef: HTMLDivElement;
  isLast?: boolean;
  to?: string;
  [key: string]: any;
}
const MenuItems = ({
  navRef,
  children,
  isLast,
  bold,
  to = '/',
  ...rest
}: IMenuItem) => {
  return (
    <Link
      href={'#' + to}
      h="100%"
      onClick={e => {
        e.preventDefault();
        navRef.scrollIntoView({ block: 'start', behavior: 'smooth' });
      }}
      _hover={{
        fontWeight: 600,
        textDecoration: 'underline',
      }}
      _focus={{ boxShadow: 'none' }}
    >
      <Flex h="100%" alignItems="center">
        <Text
          mb={{ base: isLast ? 0 : 8, sm: 0 }}
          mr={{ base: 0, sm: isLast ? 0 : 8 }}
          display="block"
          {...rest}
        >
          {children}
        </Text>
      </Flex>
    </Link>
  );
};

export interface IHeaderBarMenu {
  refs: HTMLDivElement[];
  closeMenu?: () => void;
  bold?: boolean;
  [key: string]: any;
}

export const Menu = ({ refs, bold, closeMenu, ...props }: IHeaderBarMenu) => {
  const { t } = useI18next('header');

  return (
    <Flex
      align="center"
      justify="space-between"
      direction={['column', 'row', 'row', 'row']}
      pt={[4, 4, 0, 0]}
      h="100%"
      {...props}
    >
      <MenuItems
        onClick={closeMenu}
        to="services"
        navRef={refs[0]}
        fontWeight={bold ? 600 : 200}
      >
        {t('services')}
      </MenuItems>
      <MenuItems
        onClick={closeMenu}
        to="team"
        navRef={refs[1]}
        fontWeight={bold ? 600 : 200}
      >
        {t('team')}
      </MenuItems>
      <MenuItems
        onClick={closeMenu}
        to="network"
        navRef={refs[3]}
        fontWeight={bold ? 600 : 200}
      >
        {t('network')}
      </MenuItems>
      <MenuItems
        onClick={closeMenu}
        to="references"
        navRef={refs[2]}
        fontWeight={bold ? 600 : 200}
      >
        {t('references')}
      </MenuItems>
      {/* Hide Shop products
      <MenuItems onClick={closeMenu} to="shop" navRef={refs[3]}>
        {t('shop')}
      </MenuItems> */}
      <MenuItems
        onClick={closeMenu}
        isLast
        to="contact"
        navRef={refs[4]}
        fontWeight={bold ? 600 : 200}
      >
        {t('contact')}
      </MenuItems>
    </Flex>
  );
};

export interface IHeaderBar {
  refs?: HTMLDivElement[];
  [key: string]: any;
}

export default function HeaderBar({ refs, ...props }: IHeaderBar) {
  const query = useStaticQuery(graphql`
    query HeaderQuery {
      logo: file(relativePath: { eq: "11-2022/Logo.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            height: 80
            quality: 70
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
    }
  `);

  const [showMenu, setShowMenu] = useState(false);

  const toggleMenu = () => setShowMenu(!showMenu);

  return (
    <Flex
      as="nav"
      align="center"
      justifyContent="space-between"
      wrap="wrap"
      px={8}
      bg="white"
      color="brand.500"
      {...props}
      h={['auto', `${headerHeightInPx}px`]}
    >
      <Flex align="center" h={`${headerHeightInPx}px`} w={[120, 200]}>
        <GatsbyLink to="/" style={{ height: '100%' }}>
          <GatsbyImage
            image={getImage(query.logo) as IGatsbyImageData}
            alt="brainCheck Logo"
          />
        </GatsbyLink>
      </Flex>
      {refs && (
        <Box display={{ base: 'block', md: 'none' }} onClick={toggleMenu}>
          {showMenu ? <CloseIcon /> : <HamburgerIcon />}
        </Box>
      )}
      <Box
        display={{ base: 'none', md: 'block' }}
        h="100%"
        flexBasis={{ base: '100%', md: 'auto' }}
      >
        {refs && <Menu refs={refs} />}
      </Box>
      <Box
        flexBasis={{ base: '100%', md: 'auto' }}
        display={{ base: 'block', md: 'none' }}
        h="100%"
      >
        <Collapse in={showMenu}>
          <Box py={5}>
            {refs && <Menu closeMenu={() => setShowMenu(false)} refs={refs} />}
          </Box>
        </Collapse>
      </Box>
    </Flex>
  );
}
