import { Box, Flex, Text } from '@chakra-ui/layout';
import { graphql, Link, useStaticQuery } from 'gatsby';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link as InternalLink } from '@chakra-ui/react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faLinkedin } from '@fortawesome/free-brands-svg-icons';

export const footerHeightInPx = 294;

const Footer = () => {
  const query = useStaticQuery(graphql`
    query FooterQuery {
      logo: file(relativePath: { eq: "07-2022/Logo.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            width: 200
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
    }
  `);
  const { t } = useTranslation('footer');
  return (
    <Flex
      minH={`${footerHeightInPx}px`}
      width="100vw"
      bgColor="brand.700"
      flexWrap="wrap"
      px={['10px', 0]}
      justifyContent="center"
    >
      <Box
        w={['auto', '280px']}
        flexBasis={['200px', 'auto']}
        py={['10px', '20px']}
        px={['50px', '20px']}
      >
        <GatsbyImage image={getImage(query.logo)} alt="brainCheck Logo" />
      </Box>
      <Flex flexGrow={10} flexBasis={['100%', 'auto']} alignItems="center">
        <Flex
          fontSize="18px"
          flexWrap={['wrap', 'nowrap']}
          borderTop="2px solid"
          borderColor="brand.500"
          w="100%"
          justifyContent="center"
          pt={['20px', '40px']}
        >
          <Box mr={[0, '20%']} flexBasis={['100%', 'auto']}>
            <Text fontWeight="600">brainCheck GmbH</Text>
            <Text>Römerstraße 13</Text>
            <Text>50226 Frechen</Text>
          </Box>
          <Flex mt={['20px', 0]} flexBasis={['100%', 'auto']}>
            <Box mr="20px">
              <Text fontWeight="600">Telefon</Text>
              <Text fontWeight="600">E-Mail</Text>
              <Box mt="6px">
                <InternalLink
                  mr="10px"
                  href="https://www.facebook.com/braincheckGmbH/"
                  target="_blank"
                >
                  <FontAwesomeIcon
                    icon={faFacebook}
                    style={{ fontSize: '24px' }}
                  />
                </InternalLink>
                <InternalLink
                  href="https://www.linkedin.com/company/braincheck-gmbh/"
                  target="_blank"
                >
                  <FontAwesomeIcon
                    icon={faLinkedin}
                    style={{ fontSize: '24px' }}
                  />
                </InternalLink>
              </Box>
            </Box>
            <Box>
              <Text>
                <InternalLink href="tel:+49 172 2911348">
                  +49 172 2911348
                </InternalLink>
              </Text>
              <Text>
                <InternalLink href="mailto:happy@braincheck.de">
                  happy@braincheck.de
                </InternalLink>
              </Text>
            </Box>
          </Flex>
        </Flex>
      </Flex>
      <Flex
        w={['100%', '280px']}
        flexBasis={['100%', 'auto']}
        mt={['20px', 0]}
        mb={['20px', '8px']}
        textDecoration={['underline', 'none']}
        fontWeight={[600, 300]}
        justifyContent="center"
        fontSize="18px"
        flexDirection="column"
      >
        <Link to="/impressum">
          <Text _hover={{ textDecoration: 'underline' }}>{t('impressum')}</Text>
        </Link>
        <Link to="/datenschutz">
          <Text _hover={{ textDecoration: 'underline' }}>
            {t('datenschutz')}
          </Text>
        </Link>
      </Flex>
    </Flex>
  );
};

export default Footer;
